//
// helper.scss
//

// Minimum width

.width-xs {
    min-width: 80px;
}

.width-sm {
    min-width: 100px;
}

.width-md {
    min-width: 120px;
}

.width-lg {
    min-width: 140px;
}

.width-xl {
    min-width: 160px;
}


// Font Family
.font-family-primary {
    font-family: $font-family-base;
}

.font-family-secondary {
    font-family: $font-family-secondary;
}

// Text specify lines (Only chrome browser support)

.sp-line-1,
.sp-line-2,
.sp-line-3,
.sp-line-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.sp-line-1 {
    -webkit-line-clamp: 1;
}

.sp-line-2 {
    -webkit-line-clamp: 2;
}


.sp-line-3 {
    -webkit-line-clamp: 3;
}

.sp-line-4 {
    -webkit-line-clamp: 4;
}

// Icons
.icon-dual {
    color: $text-muted;
    fill: rgba($text-muted, 0.12);
}

@each $color,
$value in $theme-colors {
    .icon-dual-#{$color} {
        color: $value;
        fill: rgba($value, 0.16);
    }
}

.icon-xs {
    height: 16px;
    width: 16px;
}
.uil.icon-xs {
    height: 24px !important;
}

.icon-sm {
    height: 24px;
    width: 24px;
}

.icon-md {
    height: 32px;
    width: 32px;
}

.icon-lg {
    height: 40px;
    width: 40px;
}

.icon-xl {
    height: 48px;
    width: 48px;
}

.icon-xxl {
    height: 60px;
    width: 60px;
}

// Hover item
.item-hovered {
    &:hover {
        background-color: $dropdown-link-hover-bg;
    }
}

// cursor
.cursor-pointer {
    cursor: pointer;
}

.prousg {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.prousg-right {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.py-15 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.icondu {
    font-size: 20px;
}
.boxde4 {
    width: 30px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .3rem;
    color: #fff;
    background-color: #ff5c75;
    font-size: 13px;
}
.procom {
    width: 250px;
    float: right;
}
.iconfont i {
    font-size: 30px;
}
.keyicon {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 100%;
    background: rgba(44, 146, 115, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #2C9273;
}
.totcirs {
    background: #FFF4F1;
    border: 1px solid #F05C50;
    border-radius: 10px;
    padding: 13px 16px;
}
.totcirs h6, .totcirs h4 {
    color: #F05C50;
}
.bgcontacts {
    background: #FAEDE4;
    overflow: hidden;
    position: relative;
}
.bgcontacts:after {
    content: "";
    position: absolute;
    width: 300px;
    height: 300px;
    min-width: 300px;
    min-height: 300px;
    border-radius: 100%;
    background: #F6E5D9;
    left: 65%;
    top: -24%;
}
.bgcontacts .list-group-item {
    background: transparent;
}
.uoweg {
    padding: 10px 16px;
    border-radius: 8px;
}
.text-base {
    color: #212529;
}
.uowegtext {
    color: #212529;
    font-weight: 500;
    font-size: 15px;
}
.uoweg.red {
    background: #FFF4F1;
}
.uoweg i {
    font-size: 17px;
    color: #2C9273;
}
.uoweg .red {
    color: #F05C50 !important;
}
.uoweg.red i {
    color: #F05C50 !important;
}
.sidetabs {
    max-width: 240px;
    min-width: 240px;
    background: #fff;
    padding: 16px;
    border: 1px solid #e2e7f1;
    border-radius: .25rem;
}
.sidetabs .nav {
    display: block;
}
.sidetabs .nav li {
    text-align: left;
}
.sidetabs .nav li a {
    font-weight: 500;
    padding-top: 12px;
    padding-bottom: 12px;
}
.sidetabs .nav-pills .nav-link.active {
    border-radius: 5px;
}
.gen {
    display: flex;
}
.sidetabs {
    margin-right: 22px;
}
.gen .tab-content {
    width: 100%;
}
.linkbtn {
    border: 1px solid rgba(44, 146, 115, 0.3);
    color: #2c9273;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin-top: -7px;
}
@media(max-width: 991px) {
    .prousg {
        justify-content: flex-start;
    }
    .procom {
        float: unset;
    }
    .prousg-right {
        justify-content: flex-start;
    }
}
@media(max-width: 650px) {
    .gen {
        display: block;
    }
    .sidetabs {
        margin-right: 0px;
        min-width: 100%;
        margin-bottom: 20px;
    }
}
.emerbg {
    background: #fdf5f0;
    padding: 16px 20px;
    border-radius: 10px;
}
.linkbtn.danger {
    border: 1px solid rgba(240, 92, 80, 0.3);
    color: #F05C50;
}
.emplbg {
    padding: 16px 20px;
    border-radius: 10px;
    border: 1px solid #e2e8f8;
}
.wauth {
    max-height: 32px;
    min-height: 32px;
    font-size: 13px;
    color: #F05C50;
    padding: 9px 16px;
}
.listskills {
    display: flex;
    flex-wrap: wrap;
}
.skills {
    background: #F9FAFB;
    border: 1px solid #EFF1F3;
    padding: 5px 18px;
    border-radius: 20px;
    margin-right: 8px;
    font-weight: 500;
    color: #212529;
}
.form-control, .form-select {
    line-height: 1.8 !important;
}
.btn-outline-success {
    color: #43d39e;
}
.delmodalicon {
    margin: 65px auto 0 auto;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    border: 1px solid rgba(217, 45, 32, .2);;
    color: #D92D20;
    display: flex;
    justify-content: center;
    align-items: center;
}
#phonenum {
    border-color: #ced4da;
    border-right: 0px;
    color: #4b4b5a;
}
#phonenum:hover, #phonenum:focus, #phonenum:active {
    background: transparent;
    background-image: escape-svg($form-select-indicator);
    background-repeat: no-repeat;
    background-position: right 0.9rem center;
    background-size: 12px 10px;
    padding-right: 32px;
    transition-duration: 5s;
}
#phonenum {
    background-image: escape-svg($form-select-indicator);
    background-repeat: no-repeat;
    background-position: right 0.9rem center;
    background-size: 12px 10px;
    padding-right: 32px;
    transition-duration: 5s;
}