.approvedinvoicedmailed {
    background-color: rgb(0, 100, 0);
  }

.approvedinvoicednotmailed {
    background-color: rgb(203, 195, 227);
}

.approvednotinvoiced {
    background-color: rgb(235, 230, 84);
}
  
.rejected {
    background-color: rgb(255, 0, 0);
  }
  
.pending {
    background-color: rgb(255, 165, 0);
  }
  
.no-placement {
    background-color: rgb(219, 219, 217);
  }