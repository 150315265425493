// 
// components-demo.scss
//

// Demo Only
.button-list {
    margin-left: -8px;
    margin-bottom: -12px;
    
    .btn {
        margin-bottom: 12px;
        margin-left: 8px;
    }
}

// Grid
.grid-structure {
    .grid-container {
        background-color: $table-striped-bg;
        margin-bottom: 10px;
        font-size: 0.8rem;
        font-weight: $font-weight-semibold;
        padding: 10px 20px;
    }
}

// Icon demo ( Demo only )
.icons-list-demo {
    div.col-sm-6 {
        cursor: pointer;
        line-height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        p {
            margin-bottom: 0;
            line-height: inherit;
        }
    }
    i {
        text-align: center;
        vertical-align: middle;
        font-size: 22px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-right: 12px;
        color: $text-muted;
        border-radius: 3px;
        display: inline-block;
        transition: all 0.2s;
    }
    .col-md-4 {
        border-radius: 3px;
        background-clip: padding-box;
        margin-bottom: 10px;
        &:hover,
        &:hover i {
            color: $primary;
        }
    }
}

.icons-list  {
    .icon-item{
        padding: 15px 0;
        color: $gray-600;
        svg{
            margin-right: 10px;
        }
        
        span{
            line-height: 30px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}


// Scrollspy

.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: .5rem;
    overflow: auto;
}

table .badge {
    padding: 0.6em 1em !important;
    font-size: 90% !important;
    font-weight: 500 !important;
}

.onlogin {
    border-radius: 0.25rem;
    position: relative;
    padding: 24px;
    color: #fff !important;
    margin-bottom: 24px;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/eficensit-test-8b2e9.appspot.com/o/MicrosoftTeams-image.png?alt=media&token=3876c3d9-d6dd-42d8-88c5-ee147532d5a9');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.password-container {
    position: relative;
  
  }

.password-toggle-icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    margin-top: 35px;
    margin-right: 5px;
  }
.onleft {
    background: rgba(44, 146, 115, 0.85);
    border-radius: 0.25rem;
    position: relative;
    padding: 24px;
    color: #fff !important;
    margin-bottom: 24px;
}
.onleft:before {
    content: url('../../../images/circle-top.svg');
    position: absolute;
    right: 0;
    top: 0;
}
.onleft:after {
    content: url('../../../images/circle-bottom.svg');
    position: absolute;
    left: 0;
    bottom: -4px;
}
.smleft {
    background: rgba(44, 146, 115, 0.85);
    border-radius: 0.25rem;
    position: relative;
    padding: 24px;
    color: #fff !important;
    margin-bottom: 24px;
}
.smleft:before {
    content: url('../../../images/hc-top.svg');
    position: absolute;
    right: 0;
    top: 0;
}
.smleft:after {
    content: url('../../../images/hc-bottom.svg');
    position: absolute;
    left: 0;
    bottom: -4px;
}
.stateicon {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    font-size: 20px;
    color: #fff;
    background: rgba(153, 224, 202, 0.3);
    width: 52px;
    height: 52px;
    border-radius: 100px;
    margin-right: 16px;
    position: relative;
    z-index: 1;
}
.idesc p {
    margin-bottom: 3px;
    color: rgba(235, 236, 240, 0.9);
    position: relative;
    z-index: 1;
}
.idesc h3 {
    font-size: 21px;
    color: #fff;
    position: relative;
    z-index: 1;
}
.stateborder {
    border-bottom: 1px solid #C1C7D0;
}
.oscard {
    background: rgba(153, 224, 202, 0.4);
    padding: 26px 22px;
    border-radius: .25rem;
    position: relative;
    z-index: 1;
}
.oscard h3 {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
}
.btn-custom {
    background: #fff;
    color: #2C9273;
    border: 3px solid #2C9273;
    text-align: center;
    width: 100%;
    padding: 9px 24px;
    font-size: 16px;    
    font-weight: 500;
    position: relative;
    z-index: 1;
    border-radius: 100px;
}
.btn-custom.btn:hover {
    opacity: .9;
}
tr:last-child td {
    border-bottom: 0px;
}
.badge-soft-primary {
    color: #405189;
    background-color: rgba(64,81,137,.1);
}
.badge-soft-secondary {
    color: #3577f1;
    background-color: rgba(53,119,241,.1);
}
.badge-soft-success {
    color: #2C9273 !important;
    background-color: #E2EEEE !important;
}
.badge-soft-info {
    color: #299cdb;
    background-color: rgba(41,156,219,.1);
}
.badge-soft-warning {
    color: #FF5E3A !important;
    background-color: #FFEBE6 !important;
}
.badge-soft-danger {
    color: #B42318 !important;
    background-color: #FEF3F2 !important;
}
.fs-12 {
    font-size: 12px !important;
}
.card-border {
    border: 1px solid #EAEDF0;
    border-radius: 8px;
}
.card-border-title {
    font-size: 18px;
    font-weight: 500;
    padding: 12px 24px;
}
.card-lists {
    padding: 24px;
    border-top: 1px solid #EAEDF0;
}
.fw-600 {
    font-weight: 600 !important;
}
.smorebtn .btn-success {
    background-color: #E2EEEE !important;
    color: #2C9273 !important;
    border-color: #E2EEEE !important;
    border-radius: 50px;
    font-size: 16px;
    padding: 10px;
}
.btn-cancel {
    background-color: #FEF3F2 !important;
    color: #B42318 !important;
    border-color: #FEF3F2 !important;
    border-radius: 50px;
    font-size: 16px;
    padding: 10px;
}
.keystates {
    padding: 16px 16px 10px 16px;
}
.mt-24 {
    margin-top: 24px;
}
.mb-24 {
    margin-bottom: 24px;
}
.btn {
    min-height: 41px;
}
.fw-500 {
    font-weight: 500 !important;
}
.text-purple {
    color: #6f42c1 !important;
}
.onstyle {
    position: relative;
    z-index: 1;

    .card-lists {
        border-color: #82beac !important;
    }
}