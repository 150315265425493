time.icon span
{
  font-size: 2em;
  letter-spacing: -0.05em;
  padding-top: 0.9em;
  color: #2f2f2f;
}

time.icon em
{
  position: absolute;
  bottom: -0.2em;
  color: #fd9f1b;
}

time.icon strong
{
  position: absolute;
  top: 0;
  padding: 0.2em 0;
  color: #fff;
  background-color: #fd9f1b;
  border-bottom: 1px dashed #f37302;
  box-shadow: 0 2px 0 #fd9f1b;
}

time.icon
{
  font-size: 0.8em; /* change icon size */
  display: block;
  position: relative;
  width: 5em;
  height: 5.3em;
  background-color: #fff;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden;
}

time.icon *
{
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}