.freezed-row {
  background-color: rgb(199, 190, 190);
}

.non-working-row {
  background-color: rgb(241, 241, 132);
}

.no-default-placement-row {
  background-color: rgb(245, 184, 143);
}

.approved {
  background-color: rgb(0, 100, 0);
}

.rejected {
  background-color: rgb(255, 0, 0);
}

.pending {
  background-color: rgb(255, 165, 0);
}

.no-placement {
  background-color: rgb(219, 219, 217);
}