.custom-header {
  writing-mode: vertical-lr;
  -ms-writing-mode: tb-rl;
}
.test-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.cell-wrap-text {
  white-space: normal !important;
}
.badge-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.test-header {
  font-size: 13px;
  margin-bottom: 5px;
  border: 1px solid rgb(199, 199, 199);
  padding: 8px;
  border-radius: 5px;
}