// 
// menu.scss
//

// Wrapper
#wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

//Content Page
.content-page {
    margin-left: $leftbar-width;
    overflow: hidden;
    padding: 0 15px 58px 15px;
    min-height: 80vh;
    margin-top: $topbar-height;
}

// Sidemenu
.left-side-menu {
    width: $leftbar-width;
    background: $bg-leftbar;
    border-right: 1px solid $gray-300;
    bottom: 0;
    padding: 20px 0;
    position: fixed;
    transition: all .1s ease-out;
    top: $topbar-height;
}

// Sidebar
#sidebar-menu {
    >ul {
        list-style: none;
        padding: 0;

        >li {
            >a {
                color: $menu-item;
                display: block;
                padding: 12px 25px;
                position: relative;
                transition: all 0.4s;
                font-family: $font-family-secondary;
                font-size: $menu-item-size;

                &:hover,
                &:focus,
                &:active {
                    color: $menu-item-hover;
                    text-decoration: none;
                    font-weight: bold;

                    svg {
                        fill: $menu-item-hover;
                    }
                }

                >span {
                    vertical-align: middle;
                }

                i {
                    display: inline-block;
                    line-height: 1.0625rem;
                    margin: 0 10px 0 3px;
                    text-align: center;
                    vertical-align: middle;
                    width: 16px;
                    font-size: 18px;
                }

                svg {
                    width: 16px;
                    height: 16px;
                    margin-left: 3px;
                    margin-right: 10px;
                    // fill: rgba($menu-item);
                }

                .drop-arrow {
                    float: right;

                    i {
                        margin-right: 0;
                    }
                }
            }

            >a.mm-active {
                color: $menu-item-active;
            }

            ul {
                padding-left: 34px;
                list-style: none;

                ul {
                    padding-left: 20px;
                }
            }
        }
    }

    .badge {
        margin-top: 4px;
    }

    .menu-title {
        padding: 25px 25px 0px;
        letter-spacing: .05em;
        pointer-events: none;
        cursor: default;
        font-size: 0.6875rem;
        text-transform: uppercase;
        color: $menu-item;
        position: relative;

        &::before {
            height: 1px;
            position: absolute;
            top: 10px;
            bottom: 10px;
            left: 15px;
            right: 15px;
            background: $border-color;
            content: "";
        }
    }

    .menuitem-active {
        > a {
            color: $menu-item-active;

            svg {
                fill: rgba($menu-item-active,0.12);
            }
        }

        .active {
            color: $menu-item-active;
            svg {
                fill: rgba($menu-item-active,0.12);
            }
        }
    }
}

.nav-second-level {
    li {
        a {
            padding: 8px 25px;
            color: $menu-item;
            display: block;
            position: relative;
            transition: all 0.4s;
            font-size: $menu-sub-item-size;

            &:focus,
            &:hover {
                color: $menu-item-hover;
            }
        }

        &.active {
            >a {
                color: $menu-item-active;
            }
        }
    }
}

.menu-arrow {
    transition: transform .15s;
    position: absolute;
    right: 20px;
    display: inline-block;
    font-family: 'unicons';
    text-rendering: auto;
    line-height: 1.8rem;
    font-size: 1rem;
    transform: translate(0, 0);

    &::before {
        content: "\e82f";
    }
}

[dir="rtl"] .menu-arrow{
    &::before {
        content: "\e82e";
    }
}

li{
    >a[aria-expanded="true"] {
        > span.menu-arrow {
            transform: rotate(90deg);
        }
    }
    &.menuitem-active {
        > a:not(.collapsed) {
            > span.menu-arrow {
                transform: rotate(90deg);
            }
        }
    }
}

// Enlarge menu
body.side-condensed {

    .logo-box {
        width: $leftbar-width-condensed !important;
        text-align: center;
        @include media-breakpoint-down(lg) {
            padding-left: 0 !important;
        }
    }

    .logo {
        span.logo-lg {
            display: none;
        }

        span.logo-sm {
            display: block;
        }
    }

    // Side menu
    .left-side-menu {
        position: absolute;
        padding-top: 0;
        width: $leftbar-width-condensed !important;
        z-index: 5;

        .simplebar-mask,
        .simplebar-content-wrapper {
            overflow: visible !important;
        }

        .simplebar-scrollbar {
            display: none !important;
        }

        .simplebar-offset {
            bottom: 0 !important;
        }

        // Sidebar Menu
        #sidebar-menu {

            .menu-title {
                span {
                    display: none;
                }
                &::before {
                    display: none;
                }
            }

            .menu-arrow,
            .label,
            .badge,
            .collapse.in {
                display: none !important;
            }

            .nav.collapse {
                height: inherit !important;
            }

            >ul {
                >li {
                    position: relative;
                    white-space: nowrap;

                    >a {
                        height: 38px;
                        width: 38px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0px;
                        transition: none;
                        margin: 5px 25px;
                        // background: rgba($menu-item, 0.2);
                        border-radius: 100px;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $menu-item-hover;
                        }

                        i {
                            font-size: 1.3rem;
                            margin-right: 20px;
                            margin-left: 5px;
                        }

                        svg {
                            width: 20px;
                            height: 20px;
                            margin-left: 6px;
                            margin-right: 6px;
                        }

                        span {
                            display: none;
                            padding-left: 25px;
                        }
                    }

                    &:hover {
                        >a {
                            position: relative;
                            color: $menu-item-active;
                            // background-color: rgba($menu-item-hover, 0.2);
                            // box-shadow: inset $box-shadow-condensed;
                        }

                        a.open,
                        a.active {
                            :after {
                                display: none;
                            }
                        }

                        > .collapse,
                        > .collapsing {
                            display: block !important;
                            height: auto !important;
                            transition: none !important;

                            > ul {
                                display: block !important;
                                left: $leftbar-width-condensed;
                                position: absolute;
                                width: 190px;
                                box-shadow: $box-shadow-condensed;
                                margin-top: -55px;
    
                                ul {
                                    box-shadow: $box-shadow-condensed;
                                }
    
                                a {
                                    box-shadow: none;
                                    padding: 8px 20px;
                                    position: relative;
                                    width: 190px;
                                    z-index: 6;
    
                                    &:hover {
                                        color: $menu-item-hover;
                                    }
                                }
                            }
                        }
                    }
                }

                .collapsing {
                    display: block !important;
                    height: auto !important;
                    transition: none !important;
                }

                ul {
                    padding: 5px 0;
                    z-index: 9999;
                    display: none;
                    background-color: $bg-leftbar;

                    li {
                        &:hover {

                            > .collapse,
                            > .collapsing {
                                display: block !important;
                                height: auto !important;
                                transition: none !important;

                                >ul {
                                    display: block;
                                    left: 190px;
                                    margin-top: -36px;
                                    position: absolute;
                                    width: 190px;
                                }
                            }
                        }

                        >a {
                            span.pull-right {
                                position: absolute;
                                right: 20px;
                                top: 12px;
                                transform: rotate(270deg);
                            }
                        }
                    }

                    li.active {
                        a {
                            color: $menu-item-active;
                        }
                    }
                }
            }
        }
    }

    // Content Page
    .content-page {
        margin-left: $leftbar-width-condensed !important;
    }

    @include media-breakpoint-up(lg) {

        //Footer
        .footer {
            left: $leftbar-width-condensed !important;
        }
    }

    //User box
    .user-box {
        display: none !important;
    }
}

// Body min-height set
@include media-breakpoint-up(md) {
    body {
        &.side-condensed:not([data-layout="compact"]) {
            min-height: $body-min-height;
        }
        &.authentication-bg {
            min-height: auto !important;
        }
    }
}

@include media-breakpoint-down(sm) {
    .pro-user-name {
        display: none;
    }
}

@include media-breakpoint-down(lg) {
    body {
        overflow-x: hidden;
        padding-bottom: 80px;
    }

    .left-side-menu {
        display: none;
        z-index: 10 !important;
    }

    .sidebar-enable {
        .left-side-menu {
            display: block;
        }
    }

    .content-page,
    body.side-condensed .content-page {
        margin-left: 0 !important;
        padding: 0 10px;
    }

    //Footer
    .footer {
        left: 0 !important;
    }
}

/* =============
  Small Menu
============= */

body[data-sidebar-size="compact"]:not(.side-condensed){
    .logo-box {
        width: $leftbar-width-sm !important;
    }

    .left-side-menu {
        width: $leftbar-width-sm !important;
        text-align: center;

        #sidebar-menu {
            >ul {

                >li {
                    >a {
                        >i {
                            display: block;
                            font-size: 18px;
                            line-height: 24px;
                            width: 100%;
                            margin: 0;
                        }

                        svg {
                            display: block;
                            margin: 0 auto 5px auto;
                        }
                    }
                }

                ul {
                    padding-left: 0;

                    a {
                        padding: 10px 20px;
                    }
                }
            }
        }

        .menu-arrow,
        .badge {
            display: none !important;
        }

        &+.content-page {
            margin-left: $leftbar-width-sm;
        }

        +.content-page .footer {
            left: $leftbar-width-sm;
        }

        .menu-title {
            background-color: $gray-100;
        }
    }
}


// Leftbar-dark
body[data-sidebar-color="dark"] {
    .logo-box {
        background-color: $bg-leftbar-dark !important;
        border-right: 1px solid $bg-leftbar-dark !important;

        .logo-dark {
            display: none;
        }

        .logo-light {
            display: block;
        }
    }

    .left-side-menu {
        background-color: $bg-leftbar-dark;
        border-right-color: $bg-leftbar-dark;
        box-shadow: none;

        #sidebar-menu {
            >ul {
                >li {
                    >a {
                        color: $menu-item-color-dark;

                        &:hover,
                        &:focus,
                        &:active {
                            color: $menu-item-hover-color-dark;
                        }
                    }

                    >a.mm-active {
                        color: $menu-item-active-color-dark;
                        background-color: lighten($bg-leftbar-dark, 2.5%);
                    }
                }
            }

            .menu-title {
                color: $gray-500;
                &::before {
                    background: $border-dark-color;
                }
            }
            
            .menuitem-active {
                > a {
                    color: $menu-item-active-color-dark;
                }
            }

        }

        .nav-second-level,
        .nav-thrid-level {
            li {
                a {
                    color: $menu-item-color-dark;

                    &:focus,
                    &:hover {
                        background-color: transparent;
                        color: $menu-item-hover-color-dark;
                    }
                }

                &.active {
                    >a {
                        color: $menu-item-active-color-dark;
                    }
                }
            }
        }

        .user-box {
            .dropdown > a {
                color: $white !important;
            }
        }
    }

    &.side-condensed {
        #wrapper {
            .left-side-menu {
                #sidebar-menu {
                    >ul {
                        >li {
                            &:hover>a {
                                background-color: $bg-leftbar-dark;
                                box-shadow: none;
                            }
                        }
                    }

                    .menuitem-active {
                        .active {
                            color: $menu-item-active;
                        }
                    }
                }
            }
        }
    }

    &[data-sidebar-size="compact"] {
        #wrapper {
            .left-side-menu {
                .menu-title {
                    background-color: rgba($white, 0.03);
                }
            }
        }
    }
}


// Leftbar with user
.user-box {
    display: none;
}

.user-pro-dropdown {
    padding: 15px 5px !important;
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;

    .dropdown-item {
        border-radius: 3px;
    }
}

// Detached Left sidebar

body[data-layout-mode="detached"] {
    .logo-box{
        background-color: transparent !important;
    }
    @include media-breakpoint-up(lg) {
        .navbar-custom {
            .container-fluid {
                max-width: 95%;
            }
        }

        #wrapper {
            max-width: 95%;
            margin: 0 auto;
        }

        .left-side-menu {
            margin-top: 30px;
            margin-bottom: 30px;
            border-radius: 5px;
            border: 1px solid $gray-300;
        }

        .content-page {
            padding-bottom: 30px;
        }

        .logo-box {
            background-color: transparent;
            background-image: none;
        }
    }
}

// Sidebar with User
body[data-sidebar-showuser="true"] {
    .user-box {
        display: block;
    }
}