// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

// .un-justify .nav-link {
//   padding-left: 45px !important;
//   padding-right: 45px !important;
// }

.d-filter-form {
  .form-control {
    background: #2C9273;
    color: #fff;
    border-color: #2C9273;
    max-width: 150px;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}